import React, { useState, useEffect, useRef } from 'react';
import { 
  Volume2, 
  VolumeX, 
  Play, 
  Pause, 
  SkipForward, 
  SkipBack,
  Settings 
} from 'lucide-react';

interface AudioPlayerProps {
  tracks?: string[];
  defaultVolume?: number;
  autoplay?: boolean;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({ 
  tracks = [
    '/audio/bgm/ambient.ogg',
    '/audio/bgm/macintosh-plus.ogg',
    '/audio/bgm/dwarves.ogg',
    '/audio/bgm/angelic.ogg',
    '/audio/bgm/triumph.ogg',
    '/audio/bgm/smooth-operator.ogg',
    '/audio/bgm/9mm.ogg'
  ],
  defaultVolume = 0.7,
  autoplay = false
}) => {
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(defaultVolume);
  const [isMuted, setIsMuted] = useState(false);
  const [trackName, setTrackName] = useState('');
  const [showVolumeSlider, setShowVolumeSlider] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(null);
  const volumeControlRef = useRef<HTMLDivElement>(null);
  const hideTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  // Initialize audio and try autoplay if requested
  useEffect(() => {
    if (autoplay && !isInitialized) {
      const tryPlayAudio = async () => {
        try {
          if (audioRef.current) {
            await audioRef.current.play();
            setIsPlaying(true);
          }
        } catch (error) {
          console.log('Auto-play prevented:', error);
          setIsPlaying(false);
        }
      };
      tryPlayAudio();
      setIsInitialized(true);
    }
  }, [autoplay, isInitialized]);

  // Handle track updates and volume
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume;
      const name = tracks[currentTrackIndex].split('/').pop()?.split('.')[0] || '';
      setTrackName(name);
      
      // If a track changes while playing, start playing the new track
      if (isPlaying) {
        const playNewTrack = async () => {
          try {
            await audioRef.current?.play();
          } catch (error) {
            console.log('Playback error:', error);
            setIsPlaying(false);
          }
        };
        playNewTrack();
      }
    }
  }, [currentTrackIndex, volume, tracks, isPlaying]);

  // Handle volume slider auto-hide
  useEffect(() => {
    if (showVolumeSlider) {
      // Clear any existing timeout
      if (hideTimeoutRef.current) {
        clearTimeout(hideTimeoutRef.current);
      }
      
      // Set new timeout
      hideTimeoutRef.current = setTimeout(() => {
        setShowVolumeSlider(false);
      }, 4000);
    }
    
    // Cleanup on unmount or when slider is hidden
    return () => {
      if (hideTimeoutRef.current) {
        clearTimeout(hideTimeoutRef.current);
      }
    };
  }, [showVolumeSlider]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        volumeControlRef.current && 
        event.target instanceof Node &&
        !volumeControlRef.current.contains(event.target)
      ) {
        setShowVolumeSlider(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const resetAutoHideTimer = () => {
    if (hideTimeoutRef.current) {
      clearTimeout(hideTimeoutRef.current);
    }
    hideTimeoutRef.current = setTimeout(() => {
      setShowVolumeSlider(false);
    }, 4000);
  };

  const togglePlay = async (e: React.MouseEvent) => {
    e.stopPropagation();
    if (audioRef.current) {
      try {
        if (isPlaying) {
          audioRef.current.pause();
        } else {
          await audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
      } catch (error) {
        console.log('Playback toggle error:', error);
      }
    }
  };

  const handleNextTrack = () => {
    setCurrentTrackIndex((prev) => (prev + 1) % tracks.length);
  };

  const nextTrackWithEvent = (e: React.MouseEvent) => {
    e.stopPropagation();
    handleNextTrack();
  };

  const previousTrack = (e: React.MouseEvent) => {
    e.stopPropagation();
    setCurrentTrackIndex((prev) => (prev - 1 + tracks.length) % tracks.length);
  };

  const toggleMute = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (audioRef.current) {
      audioRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  const toggleVolumeSlider = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowVolumeSlider(!showVolumeSlider);
  };

  const handleVolumeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);
    if (audioRef.current) {
      audioRef.current.volume = newVolume;
    }
  };

  const handleSliderInteractionStart = (e: React.MouseEvent | React.TouchEvent) => {
    e.stopPropagation();
  };

  return (
    <div className="p-4 bg-black/80 border border-red-500/30" onClick={(e) => e.stopPropagation()}>
      <audio
        ref={audioRef}
        src={tracks[currentTrackIndex]}
        onEnded={handleNextTrack}
        loop={false}
      />
      
      {/* Track name display */}
      <div className="mb-4">
        <p className="text-lime-600 font-mono truncate text-sm">
          {`TRACK_${currentTrackIndex + 1}//${trackName}`}
        </p>
      </div>

      {/* Controls */}
      <div className="flex items-center space-x-4">
        <button 
          onClick={previousTrack}
          className="text-lime-600 hover:text-lime-500 transition-colors p-1"
        >
          <SkipBack className="w-5 h-5" />
        </button>

        <button 
          onClick={togglePlay}
          className="text-lime-600 hover:text-lime-500 transition-colors p-1"
        >
          {isPlaying ? (
            <Pause className="w-6 h-6" />
          ) : (
            <Play className="w-6 h-6" />
          )}
        </button>

        <button 
          onClick={nextTrackWithEvent}
          className="text-lime-600 hover:text-lime-500 transition-colors p-1"
        >
          <SkipForward className="w-5 h-5" />
        </button>

        <div ref={volumeControlRef} className="relative">
          <button
            onClick={toggleVolumeSlider}
            className="text-lime-600 hover:text-lime-500 transition-colors p-1"
          >
            {isMuted || volume === 0 ? (
              <VolumeX className="w-5 h-5" />
            ) : (
              <Volume2 className="w-5 h-5" />
            )}
          </button>

          {showVolumeSlider && (
            <div 
              className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 w-32 p-2 bg-black border border-lime-600/30 rounded"
              onClick={(e) => e.stopPropagation()}
              onMouseDown={handleSliderInteractionStart}
              onTouchStart={handleSliderInteractionStart}
              onMouseMove={resetAutoHideTimer}
              onTouchMove={resetAutoHideTimer}
            >
              <input
                type="range"
                min="0"
                max="1"
                step="0.01"
                value={volume}
                onChange={handleVolumeChange}
                onMouseDown={handleSliderInteractionStart}
                onTouchStart={handleSliderInteractionStart}
                onMouseMove={resetAutoHideTimer}
                onTouchMove={resetAutoHideTimer}
                className="w-full h-1 bg-lime-600/30 rounded-lg appearance-none cursor-pointer
                         [&::-webkit-slider-thumb]:appearance-none
                         [&::-webkit-slider-thumb]:w-3
                         [&::-webkit-slider-thumb]:h-3
                         [&::-webkit-slider-thumb]:rounded-full
                         [&::-webkit-slider-thumb]:bg-lime-500
                         [&::-webkit-slider-thumb]:cursor-pointer"
              />
            </div>
          )}
        </div>

        <button 
          onClick={toggleMute}
          className="text-lime-600 hover:text-lime-500 transition-colors ml-auto p-1"
        >
          <Settings className="w-5 h-5" />
        </button>
      </div>
    </div>
  );
};

export default AudioPlayer;