import React from 'react';

interface CRTEffectProps {
  children: React.ReactNode;
  enabled?: boolean;
  intensity?: number; // 0 to 1
  className?: string;
}

const CRTEffect: React.FC<CRTEffectProps> = ({ 
  children, 
  enabled = true,
  intensity = 0.5,
  className = '' 
}) => {
  if (!enabled) return <>{children}</>;

  const scanlineOpacity = 0.6 * intensity;
  const distortionStrength = 0.15 * intensity;
  const vignetteOpacity = 0.5 * intensity;

  return (
    <>
      <style>
        {`
          @keyframes scanlines {
            from {
              background-position: 0 0;
            }
            to {
              background-position: 0 2px;
            }
          }

          .crt-text {
            text-shadow: 
              0 0 1px rgba(255,255,255,0.7),
              0 0 2px rgba(255,255,255,0.5),
              0 0 3px rgba(255,255,255,0.3);
          }

          .crt-glow {
            box-shadow: 
              0 0 3px rgba(255,255,255,0.7),
              0 0 6px rgba(255,255,255,0.5),
              0 0 9px rgba(255,255,255,0.3),
              inset 0 0 3px rgba(255,255,255,0.7),
              inset 0 0 6px rgba(255,255,255,0.5);
          }
        `}
      </style>
      
      <div
        className={`crt-wrapper relative ${className}`}
        style={{
          isolation: 'isolate'
        }}
      >
        {/* Main content */}
        <div className="relative">
          {children}
        </div>

        {/* Color bleed and distortion */}
        <div
          className="pointer-events-none fixed inset-0 z-[98]"
          style={{
            background: 'transparent',
            mixBlendMode: 'color',
            backdropFilter: `blur(${0.3 * intensity}px)`,
          }}
        />

        {/* Scanlines */}
        <div
          className="pointer-events-none fixed inset-0 z-[100]"
          style={{
            background: `
              repeating-linear-gradient(
                0deg,
                rgba(0, 0, 0, ${scanlineOpacity}) 0%,
                rgba(0, 0, 0, ${scanlineOpacity * 0.7}) 1px,
                rgba(0, 0, 0, 0) 2px,
                rgba(0, 0, 0, 0) 4px
              ),
              linear-gradient(
                90deg,
                rgba(255, 0, 0, ${distortionStrength}),
                rgba(0, 255, 0, ${distortionStrength * 0.5}),
                rgba(0, 0, 255, ${distortionStrength})
              )
            `,
            backgroundSize: '100% 4px, 100% 100%',
            animation: 'scanlines 5s linear infinite',
            opacity: 0.9
          }}
        />

        {/* Screen curvature/vignette */}
        <div
          className="pointer-events-none fixed inset-0 z-[99]"
          style={{
            background: `radial-gradient(
              circle at center,
              transparent 10%,
              rgba(0, 0, 0, ${vignetteOpacity * 0.5}) 60%,
              rgba(0, 0, 0, ${vignetteOpacity}) 100%
            )`,
            mixBlendMode: 'multiply'
          }}
        />

        {/* Additional color aberration */}
        <div
          className="pointer-events-none fixed inset-0 z-[97]"
          style={{
            boxShadow: `
              inset ${2 * intensity}px 0 ${1 * intensity}px rgba(255,0,0,0.4),
              inset -${2 * intensity}px 0 ${1 * intensity}px rgba(0,255,255,0.4)
            `,
            mixBlendMode: 'screen'
          }}
        />
      </div>
    </>
  );
};

export default CRTEffect;