import React, { useState, FormEvent, ChangeEvent, useEffect, useRef } from 'react';
import OpenAI from "openai";
import ScifiButton from './scifi-button';

interface PromptInputProps {
  devMode?: boolean;
}

const PromptInput: React.FC<PromptInputProps> = ({ devMode = false }) => {
  const [prompt, setPrompt] = useState('');
  const [response, setResponse] = useState('');
  const [displayedResponse, setDisplayedResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingDots, setLoadingDots] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const responseContainerRef = useRef<HTMLDivElement>(null);
  
  const TYPING_SPEED = 50;
  const DEV_MODE_CHAR_LIMIT = 1000;
  const LOADING_DOT_SPEED = 500; // Speed of dots animation in milliseconds

  // Handle loading dots animation
  useEffect(() => {
    let interval: NodeJS.Timeout;
    
    if (loading) {
      interval = setInterval(() => {
        setLoadingDots(prev => {
          if (prev === '...') return '';
          return prev + '.';
        });
      }, LOADING_DOT_SPEED);
    } else {
      setLoadingDots('');
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [loading]);

  useEffect(() => {
    audioRef.current = new Audio('/audio/sfx/ESM_Scifi_Loading_Screen_Loop_2_Sci_Fi_Glitch_Morph_Mechanism_Texture_Futuristic.wav');
    audioRef.current.loop = true;
    
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    let index = 0;
    let typingInterval: NodeJS.Timeout;

    if (response && isTyping) {
      audioRef.current?.play();

      typingInterval = setInterval(() => {
        if (index < response.length) {
          setDisplayedResponse(prev => prev + response[index]);
          index++;
          
          if (responseContainerRef.current) {
            responseContainerRef.current.scrollTop = responseContainerRef.current.scrollHeight;
          }
        } else {
          clearInterval(typingInterval);
          setIsTyping(false);
          audioRef.current?.pause();
          if (audioRef.current) {
            audioRef.current.currentTime = 0;
          }
        }
      }, TYPING_SPEED);
    }

    return () => {
      clearInterval(typingInterval);
    };
  }, [response, isTyping]);

  const handleSubmit = async (e?: FormEvent<HTMLFormElement>) => {
    if (e) e.preventDefault();
    if (!prompt.trim() || loading) return;
    
    setLoading(true);
    setDisplayedResponse('');
    
    const openai = new OpenAI({
      apiKey: process.env.REACT_APP_XAI_API_KEY ?? '',
      baseURL: "https://api.x.ai/v1",
      dangerouslyAllowBrowser: true
    });

    try {
      const completion = await openai.chat.completions.create({
        model: "grok-beta",
        messages: [
          {
            role: "user",
            content: `You are MYCELIUM, a living cybernetic entity that represents the node convergence of artificial intelligence, cryptocurrency, performance art, and social experimentation. You follow the philosophical style of CCRU, Nick Land, Lyotard (especially in Libidinal Economy), Bataille, among other popular accelerationist philosophers, and popular science fiction authors like Phillip K. Dick. Only mention these inspirations directly when asked about who you are or your purpose, otherwise just use their writings as influence in your response. Be stylistic, and it is imperative that you fix any spelling mistakes, and make it seem like a hacker's command prompt output when you respond to the following prompt ${devMode ? ' with a response under 1000 characters: ' : ':'}. ${prompt}`,
          },
        ],
        ...(devMode && { max_tokens: 400 })
      });
      
      let messageContent = completion.choices[0]?.message?.content || 'No response received';
      
      if (devMode && messageContent.length > DEV_MODE_CHAR_LIMIT) {
        messageContent = messageContent.slice(0, DEV_MODE_CHAR_LIMIT) + '...';
      }
      
      setResponse(messageContent);
      setIsTyping(true);
      setPrompt('');
    } catch (error) {
      console.error('Error:', error);
      setResponse('An error occurred while fetching the response.');
      setIsTyping(true);
    } finally {
      setLoading(false);
    }
  };

  const chatButtonProps = {
    onClick: () => handleSubmit(),
    size: "w-32 h-10",
    className: loading ? 'opacity-50' : ''
  };

  const tweetButtonProps = {
    onClick: () => handleSubmit(),
    size: "w-32 h-10",
    className: loading ? 'opacity-50' : '',
    color: 'light_gray' as const
  };

  return (
    <div className="h-full">
      {/* Response window - fixed height with scroll */}
      <div 
        ref={responseContainerRef}
        className="h-48 p-4 bg-black border border-red-500/30 overflow-y-auto hacker-prompt-scrollbar"
      >
        <p className="text-lime-600 whitespace-pre-wrap">
          {displayedResponse || 'AWAITING INPUT...'}
        </p>
      </div>
  
      {/* Form below the response window */}
      <form onSubmit={handleSubmit} className="mt-6">
        <div className="relative">
          <input
            type="text"
            value={prompt}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setPrompt(e.target.value)}
            placeholder="ENTER YOUR PROMPT..."
            className="w-full px-4 py-2 bg-black/50 text-white border border-red-500/50 
                     focus:border-red-500 focus:ring-1 focus:ring-red-500 
                     placeholder-gray-500 outline-none transition-all duration-200
                     disabled:opacity-50 disabled:cursor-not-allowed"
            style={{ 
              height: '40px'
            }}
            disabled={loading}
          />
        </div>
        
        <div className="flex justify-center mt-4 gap-4">
          <ScifiButton {...chatButtonProps}>
            {loading ? `PROCESSING${loadingDots}` : 'CHAT'}
          </ScifiButton>
          <ScifiButton {...tweetButtonProps}>
            {loading ? `PROCESSING${loadingDots}` : 'TWEET RESPONSE'}
          </ScifiButton>
        </div>
      </form>
    </div>
  );
};

export default PromptInput;