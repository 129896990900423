import React, { useState, useEffect, useRef } from 'react';
import React9Slice from './react9slice';
import { convertTailwindToPixels } from '../../utils/tailwind-utils';

interface Position {
  x: number;
  y: number;
}

interface Size {
  width: number;
  height: number;
}

interface DraggableWindowProps {
  position: Position;
  defaultSize?: string;
  currentSize?: Size | null;
  minSize?: string;
  maxSize?: string;
  allowResize?: boolean;
  children?: React.ReactNode;
  imageUrl: string;
  imageWidth: number;
  imageHeight: number;
  borderSize?: number;
  className?: string;
  style?: React.CSSProperties;
  id: string;
  zIndex: number;
  onFocus: (id: string) => void;
  onMove: (position: Position) => void;
  onResize: (size: Size) => void;
}

const DraggableWindow: React.FC<DraggableWindowProps> = ({
  position,
  defaultSize = 'w-64 h-48',
  currentSize,
  minSize = 'w-48 h-32',
  maxSize = 'w-96 h-80',
  allowResize = true,
  children,
  imageUrl,
  imageWidth,
  imageHeight,
  borderSize = 16,
  className = '',
  style,
  id,
  zIndex,
  onFocus,
  onMove,
  onResize,
}) => {
  const getInitialSize = (sizeClasses: string | undefined): Size => {
    if (!sizeClasses) {
      console.warn('No size classes provided, using defaults');
      return { width: 256, height: 192 };
    }
    
    try {
      const classes = sizeClasses.split(' ');
      const widthClass = classes.find(cls => cls.startsWith('w-'));
      const heightClass = classes.find(cls => cls.startsWith('h-'));
      
      return {
        width: convertTailwindToPixels(widthClass) || 256,
        height: convertTailwindToPixels(heightClass) || 192
      };
    } catch (error) {
      console.warn('Error parsing size classes, using defaults');
      return { width: 256, height: 192 };
    }
  };

  const minSizePixels = getInitialSize(minSize);
  const maxSizePixels = getInitialSize(maxSize);
  
  const [size, setSize] = useState<Size>(
    currentSize || getInitialSize(defaultSize)
  );
  const [isDragging, setIsDragging] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [resizeDirection, setResizeDirection] = useState<string | null>(null);
  
  const windowRef = useRef<HTMLDivElement>(null);
  const dragStart = useRef<Position>({ x: 0, y: 0 });
  const sizeAtResizeStart = useRef<Size>({ width: 0, height: 0 });

  useEffect(() => {
    if (currentSize) {
      setSize(currentSize);
    } else {
      setSize(getInitialSize(defaultSize));
    }
  }, [currentSize, defaultSize]);

  const handleMouseDown = (e: React.MouseEvent) => {
    if (e.target instanceof HTMLElement && e.target.classList.contains('resize-handle')) {
      return;
    }
    
    onFocus(id);
    setIsDragging(true);
    dragStart.current = { x: e.clientX, y: e.clientY };
    e.stopPropagation();
    document.body.style.cursor = 'grabbing';
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    if (e.target instanceof HTMLElement && e.target.classList.contains('resize-handle')) {
      return;
    }
    
    onFocus(id);
    setIsDragging(true);
    dragStart.current = { 
      x: e.touches[0].clientX, 
      y: e.touches[0].clientY 
    };
    e.stopPropagation();
  };

  const handleResizeStart = (e: React.MouseEvent, direction: string) => {
    if (!allowResize) return;
    e.preventDefault();
    e.stopPropagation();
    onFocus(id);
    setIsResizing(true);
    setResizeDirection(direction);
    dragStart.current = { x: e.clientX, y: e.clientY };
    sizeAtResizeStart.current = size;
  };

  const handleResizeTouchStart = (e: React.TouchEvent, direction: string) => {
    if (!allowResize) return;
    e.preventDefault();
    e.stopPropagation();
    onFocus(id);
    setIsResizing(true);
    setResizeDirection(direction);
    dragStart.current = { 
      x: e.touches[0].clientX, 
      y: e.touches[0].clientY 
    };
    sizeAtResizeStart.current = size;
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
    setIsResizing(false);
    setResizeDirection(null);
  };

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (isDragging) {
        const deltaX = e.clientX - dragStart.current.x;
        const deltaY = e.clientY - dragStart.current.y;
        onMove({
          x: position.x + deltaX,
          y: position.y + deltaY,
        });
        dragStart.current = { x: e.clientX, y: e.clientY };
      } else if (isResizing && allowResize) {
        const deltaX = e.clientX - dragStart.current.x;
        const deltaY = e.clientY - dragStart.current.y;
        
        let newWidth = sizeAtResizeStart.current.width;
        let newHeight = sizeAtResizeStart.current.height;

        // Handle corner resize
        if (resizeDirection === 'se') {
          newWidth += deltaX;
          newHeight += deltaY;
        }
        // Handle bottom edge resize
        else if (resizeDirection === 's') {
          newHeight += deltaY;
        }

        newWidth = Math.max(minSizePixels.width, Math.min(maxSizePixels.width, newWidth));
        newHeight = Math.max(minSizePixels.height, Math.min(maxSizePixels.height, newHeight));

        onResize({ width: newWidth, height: newHeight });
        setSize({ width: newWidth, height: newHeight });
      }
    };

    const handleTouchMove = (e: TouchEvent) => {
      if (isDragging) {
        const deltaX = e.touches[0].clientX - dragStart.current.x;
        const deltaY = e.touches[0].clientY - dragStart.current.y;
        onMove({
          x: position.x + deltaX,
          y: position.y + deltaY,
        });
        dragStart.current = { 
          x: e.touches[0].clientX, 
          y: e.touches[0].clientY 
        };
      } else if (isResizing && allowResize) {
        const deltaX = e.touches[0].clientX - dragStart.current.x;
        const deltaY = e.touches[0].clientY - dragStart.current.y;
        
        let newWidth = sizeAtResizeStart.current.width;
        let newHeight = sizeAtResizeStart.current.height;

        // Handle corner resize
        if (resizeDirection === 'se') {
          newWidth += deltaX;
          newHeight += deltaY;
        }
        // Handle bottom edge resize
        else if (resizeDirection === 's') {
          newHeight += deltaY;
        }

        newWidth = Math.max(minSizePixels.width, Math.min(maxSizePixels.width, newWidth));
        newHeight = Math.max(minSizePixels.height, Math.min(maxSizePixels.height, newHeight));

        onResize({ width: newWidth, height: newHeight });
        setSize({ width: newWidth, height: newHeight });
      }
    };

    const handleMouseUp = () => {
      setIsDragging(false);
      setIsResizing(false);
      setResizeDirection(null);
      document.body.style.cursor = 'default';
    };

    if (isDragging || isResizing) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
      document.addEventListener('touchmove', handleTouchMove, { passive: false });
      document.addEventListener('touchend', handleTouchEnd);
      document.body.style.userSelect = 'none';
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
      document.body.style.userSelect = 'initial';
      document.body.style.cursor = 'default';
    };
  }, [isDragging, isResizing, position, allowResize, minSizePixels, maxSizePixels, resizeDirection, onMove, onResize]);

  return (
    <div
      ref={windowRef}
      className={`absolute select-none touch-none ${className}`}
      style={{
        transform: `translate(${position.x}px, ${position.y}px)`,
        cursor: isDragging ? 'grabbing' : 'grab',
        zIndex,
      }}
      onMouseDown={handleMouseDown}
      onTouchStart={handleTouchStart}
    >
      <React9Slice
        image={imageUrl}
        width={size.width}
        height={size.height}
        border={borderSize}
        imageWidth={imageWidth}
        imageHeight={imageHeight}
        style={style}
      >
        <div className="text-white h-full overflow-y-auto -mt-1 pb-[3px] pr-0.5">
          {children}
        </div>
      </React9Slice>
      {allowResize && (
        <>
          <div 
            className="absolute bottom-0 w-full h-1 cursor-s-resize resize-handle z-50" 
            onMouseDown={(e) => handleResizeStart(e, 's')}
            onTouchStart={(e) => handleResizeTouchStart(e, 's')}
          />
          <div 
            className="absolute bottom-0 right-0 w-2 h-2 cursor-se-resize resize-handle z-50" 
            onMouseDown={(e) => handleResizeStart(e, 'se')}
            onTouchStart={(e) => handleResizeTouchStart(e, 'se')}
          />
        </>
      )}
    </div>
  );
};

export default DraggableWindow;