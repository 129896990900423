// tailwind-utils.ts

// Type definitions
type TailwindSizeMap = Record<string, string>;
type Breakpoint = 'base' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
// ResponsiveSizes is used in the size mapping system
type ResponsiveSizes = Record<Breakpoint, number>;

// Breakpoint definitions
const breakpoints: ResponsiveSizes = {
  base: 0, // Added base breakpoint
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536
};

// Size mapping
const tailwindSizeMap: TailwindSizeMap = {
  // Width/Height
  'h-0': '0px',
  'h-1': '0.25rem',
  'h-2': '0.5rem',
  'h-4': '1rem',
  'h-8': '2rem',
  'h-12': '3rem',
  'h-16': '4rem',
  'h-20': '5rem',
  'h-24': '6rem',
  'h-32': '8rem',
  'h-40': '10rem',
  'h-48': '12rem',
  'h-56': '14rem',
  'h-64': '16rem',
  'h-72': '18rem',
  'h-80': '20rem',
  'h-96': '24rem',
  'h-128': '32rem', // Custom size
  'h-256': '64rem', // Custom size
  'w-0': '0px',
  'w-1': '0.25rem',
  'w-2': '0.5rem',
  'w-4': '1rem',
  'w-8': '2rem',
  'w-12': '3rem',
  'w-16': '4rem',
  'w-20': '5rem',
  'w-24': '6rem',
  'w-32': '8rem',
  'w-40': '10rem',
  'w-48': '12rem',
  'w-56': '14rem',
  'w-64': '16rem',
  'w-72': '18rem',
  'w-80': '20rem',
  'w-96': '24rem',
  'w-128': '32rem', // Custom size
  'w-256': '64rem', // Custom size
  
  // Percentage widths
  'w-1/2': '50%',
  'w-1/3': '33.333333%',
  'w-2/3': '66.666667%',
  'w-1/4': '25%',
  'w-3/4': '75%',
  'w-full': '100%',
  
  // Spacing (for positioning)
  'top-0': '0px',
  'top-1': '0.25rem',
  'top-2': '0.5rem',
  'top-4': '1rem',
  'top-8': '2rem',
  'top-10': '2.5rem',
  'top-12': '3rem',
  'top-16': '4rem',
  'top-20': '5rem',
  'left-0': '0px',
  'left-1': '0.25rem',
  'left-2': '0.5rem',
  'left-4': '1rem',
  'left-8': '2rem',
  'left-10': '2.5rem',
  'left-12': '3rem',
  'left-16': '4rem',
  'left-20': '5rem',
  
  // Text sizes
  'text-xs': '0.75rem',
  'text-sm': '0.875rem',
  'text-base': '1rem',
  'text-lg': '1.125rem',
  'text-xl': '1.25rem',
  'text-2xl': '1.5rem',
  'text-3xl': '1.875rem',
  'text-4xl': '2.25rem',
  
  // Padding
  'p-0': '0px',
  'p-1': '0.25rem',
  'p-2': '0.5rem',
  'p-4': '1rem',
  'p-8': '2rem',
};

// Core conversion functions
const convertRemToPixels = (rem: string): number => {
  return parseFloat(rem) * 16;
};

const convertPercentageToPixels = (percentage: string, containerSize: number = 0): number => {
  return (parseFloat(percentage) / 100) * containerSize;
};

export const getTailwindSize = (className: string): number => {
  const value = tailwindSizeMap[className];
  if (!value) return 0;
  
  if (value.endsWith('rem')) {
    return convertRemToPixels(value);
  } else if (value.endsWith('px')) {
    return parseFloat(value);
  } else if (value.endsWith('%')) {
    return convertPercentageToPixels(value);
  }
  return 0;
};

// Responsive utilities
export const parseResponsiveClasses = (classes: string): Record<Breakpoint, string> => {
  const result: Partial<Record<Breakpoint, string>> = { base: '' };
  
  classes.split(' ').forEach(cls => {
    // Check for responsive prefix
    const breakpointMatch = cls.match(/^(sm:|md:|lg:|xl:|2xl:)(.*)/);
    if (breakpointMatch) {
      const [, prefix, className] = breakpointMatch;
      const breakpoint = prefix.replace(':', '') as Breakpoint;
      result[breakpoint] = className;
    } else {
      result.base = cls;
    }
  });
  
  return result as Record<Breakpoint, string>;
};

export const getCurrentBreakpoint = (): Breakpoint => {
  if (typeof window === 'undefined') return 'base';
  
  if (window.matchMedia(`(min-width: ${breakpoints['2xl']}px)`).matches) return '2xl';
  if (window.matchMedia(`(min-width: ${breakpoints.xl}px)`).matches) return 'xl';
  if (window.matchMedia(`(min-width: ${breakpoints.lg}px)`).matches) return 'lg';
  if (window.matchMedia(`(min-width: ${breakpoints.md}px)`).matches) return 'md';
  if (window.matchMedia(`(min-width: ${breakpoints.sm}px)`).matches) return 'sm';
  return 'base';
};

export const getResponsiveSize = (classes: string, containerSize: number = 0): number => {
  const responsiveClasses = parseResponsiveClasses(classes);
  const currentBreakpoint = getCurrentBreakpoint();
  
  // Find the most appropriate class for current breakpoint
  let appropriateClass = responsiveClasses[currentBreakpoint];
  if (!appropriateClass) {
    // Fall back to smaller breakpoints if current one isn't defined
    const breakpointOrder: Breakpoint[] = ['2xl', 'xl', 'lg', 'md', 'sm', 'base'];
    const currentIndex = breakpointOrder.indexOf(currentBreakpoint);
    
    for (let i = currentIndex + 1; i < breakpointOrder.length; i++) {
      if (responsiveClasses[breakpointOrder[i]]) {
        appropriateClass = responsiveClasses[breakpointOrder[i]];
        break;
      }
    }
  }
  
  return appropriateClass ? getTailwindSize(appropriateClass) : 0;
};

export const convertTailwindToPixels = (className: string | undefined, containerSize: number = 0): number => {
  if (!className) return 0;
  return getResponsiveSize(className, containerSize);
};

// Create utils object before export
const tailwindUtils = {
  getTailwindSize,
  getResponsiveSize,
  getCurrentBreakpoint,
  convertTailwindToPixels,
  parseResponsiveClasses,
};

export default tailwindUtils;