import React, { useState, useEffect } from 'react';
import React9Slice from './react9slice';
import { convertTailwindToPixels, getCurrentBreakpoint } from '../../utils/tailwind-utils';

type ButtonColor = 'blue' | 'dark_gray' | 'green' | 'light_gray' | 'orange' | 'red' | 'yellow' | 'violet';


interface ScifiButtonProps {
  onClick: () => void;
  children: React.ReactNode;
  size?: string;
  className?: string;
  color?: ButtonColor;
}

const ScifiButton: React.FC<ScifiButtonProps> = ({
  onClick,
  children,
  size = 'w-48 h-10 md:w-56 md:h-12',
  className = '',
  color = 'red'
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isPressed, setIsPressed] = useState(false);
  const [buttonSize, setButtonSize] = useState({
    width: 0,
    height: 0
  });

  useEffect(() => {
    const updateSize = () => {
      const classes = size.split(' ');
      const currentBreakpoint = getCurrentBreakpoint();
      
      let widthClass = classes.find(cls => cls.startsWith(`${currentBreakpoint}:w-`));
      if (!widthClass) {
        widthClass = classes.find(cls => cls.startsWith('w-') && !cls.includes(':'));
      }
      widthClass = widthClass?.replace(`${currentBreakpoint}:`, '');

      let heightClass = classes.find(cls => cls.startsWith(`${currentBreakpoint}:h-`));
      if (!heightClass) {
        heightClass = classes.find(cls => cls.startsWith('h-') && !cls.includes(':'));
      }
      heightClass = heightClass?.replace(`${currentBreakpoint}:`, '');

      setButtonSize({
        width: convertTailwindToPixels(widthClass) || 192,
        height: convertTailwindToPixels(heightClass) || 40
      });
    };

    updateSize();
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, [size]);

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    onClick();
  };

  const handleMouseDown = () => {
    setIsPressed(true);
  };

  const handleMouseUp = () => {
    setIsPressed(false);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setIsPressed(false);
  };

  const getButtonImage = () => {
    const basePath = `/images/scifi/window_theme/window_theme_${color}/`;
    
    if (isPressed) {
      return `${basePath}button_pressed.png`;
    }
    if (isHovered) {
      return `${basePath}button_hovered.png`;
    }
    return `${basePath}button_default.png`;
  };

  return (
    <div
      className={`relative cursor-pointer select-none ${className}`}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
    >
      <React9Slice
        image={getButtonImage()}
        width={buttonSize.width}
        height={buttonSize.height}
        imageWidth={96}
        imageHeight={96}
        border={8}
        style={{ padding: '4px' }}
      >
        <div className="flex items-center justify-center w-full h-full">
          <div className="text-white text-sm font-medium">
            {children}
          </div>
        </div>
      </React9Slice>
    </div>
  );
};

export default ScifiButton;