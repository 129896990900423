import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../index.css';
import ScifiButton from '../components/ui/scifi-button';
import CRTEffect from '../components/other/crt-effect';

const HomePage = () => {
  const [showButton, setShowButton] = useState(true);
  const [showVideo, setShowVideo] = useState(false);
  const [showSkipButton, setShowSkipButton] = useState(false);
  const navigate = useNavigate();

  const handleEnter = () => {
    setShowButton(false);
    setShowVideo(true);
    setTimeout(() => {
      setShowSkipButton(true);
    }, 3000);
  };

  const handleSkip = () => {
    navigate('/desktop', { state: { startAudio: true } });
  };

  useEffect(() => {
    if (showVideo) {
      const video = document.querySelector('video');
      video.addEventListener('ended', () => {
        navigate('/desktop', { state: { startAudio: true } });
      });
    }
  }, [showVideo, navigate]);

  return (
    <CRTEffect intensity={0.4}>
      <div className="min-h-screen bg-black flex items-center justify-center">
        {showButton && (
          <ScifiButton
            onClick={handleEnter}
            size="w-36 h-10 md:w-48 md:h-12" // Smaller on mobile, larger on desktop
          >
            BOOT
          </ScifiButton>
        )}
        
        {showVideo && (
          <>
           <video
              autoPlay
              playsInline
              className="absolute w-full h-full object-contain aspect-video
                sm:object-cover aspect-video
                md:object-cover aspect-video"
            >
              <source src="/images/bg/boot.webm" type="video/webm" />
          </video>

            {showSkipButton && (
              <div
                className="absolute w-full flex justify-center bottom-[10%]" // Using Tailwind instead of inline style
              >
                <ScifiButton
                  onClick={handleSkip}
                  size="w-48 h-10 md:w-56 md:h-12" // Responsive sizing
                  className="z-50 px-2" // Added padding for text
                >
                  SKIP BOOT SEQUENCE
                </ScifiButton>
              </div>
            )}
          </>
        )}
      </div>
    </CRTEffect>
  );
};
export default HomePage;