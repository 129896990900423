import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import TokenInfo from './pages/TokenInfo';
import Contact from './pages/ContactForm';
import Desktop from './pages/Desktop';
import Network from './pages/Network';
import { SolanaWalletProvider } from './components/other/solana-wallet-provider';


const App = () => {
  return (
    <SolanaWalletProvider>
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/token" element={<TokenInfo />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/desktop" element={<Desktop/>} />
        <Route path="/network" element={<Network/>} />
      </Routes>
    </Router>
  </SolanaWalletProvider>

  );
};

export default App;
